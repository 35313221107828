import { AttachFileTwoTone, ListAltTwoTone } from '@mui/icons-material';
import { Button, Grid, Stack, Tooltip } from '@mui/material';
import React, { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
    ContactType,
    Definition,
    formatDateString,
    formatDateTimeString,
    IParams,
    Page,
    PermissionKeys,
    RemoveModal,
    useHasPermission,
    Widget,
} from '../../../shared';
import { Asset, OrganisationProgressCategories, ProductProgress, ShipmentDetailStockMovements } from '../../components';
import { ShipmentStatus } from '../../enums';
import { useDeleteShipment, useNotifyShipment, useSaveShipment, useShipment } from '../../hooks';

export const ShipmentDetailPage: FC = () => {
    const { t } = useTranslation();
    const { id } = useParams<keyof IParams>() as IParams;
    const navigate = useNavigate();
    const { state: redirectToDashboard } = useLocation();
    const { hasPermission } = useHasPermission();
    const hasWritePermission = hasPermission(PermissionKeys.SHIPMENTS_WRITE);

    const { data: shipment, isPending: shipmentIsPending } = useShipment(id, { refetchInterval: 5000 });
    const { mutateAsync: deleteShipment, isPending: isPendingDelete } = useDeleteShipment();
    const { mutateAsync: notifyShipment, isPending: notifying } = useNotifyShipment();
    const { mutateAsync: saveShipment, isPending: isPendingSave } = useSaveShipment();

    const emailConfigured = useMemo(() => {
        return shipment?.receiver?.contacts
            ? shipment?.receiver.contacts?.filter((contact) => contact.type === ContactType.Email)?.length > 0
            : false;
    }, [shipment]);

    const onNotify = useCallback(async () => {
        await notifyShipment(id);
    }, [id, notifyShipment]);

    const onComplete = useCallback(async () => {
        if (shipment) {
            await saveShipment({
                id,
                item: {
                    id,
                    receiverId: shipment.receiver.id,
                    shipmentDate: shipment.shipmentDate,
                    stockMovements: shipment.stockMovements.map(({ id }) => id),
                    status: ShipmentStatus.COMPLETED,
                },
            });
        }
    }, [id, saveShipment, shipment]);

    const onEdit = useCallback(async () => {
        navigate(`/planning/shipments/${id}`);
    }, [id, navigate]);

    const onDelete = useCallback(async () => {
        await deleteShipment(id);
        navigate('/planning/shipments');
    }, [deleteShipment, id, navigate]);

    return (
        <Page
            title={t(`shipmentDetails`, {
                organisation: shipment && shipment.receiver?.name,
                date: shipment && formatDateString(shipment.shipmentDate),
                interpolation: { escapeValue: false },
            })}
            onBack={() => navigate(redirectToDashboard ? redirectToDashboard : '/planning/shipments')}
            loading={shipmentIsPending || isPendingDelete}
            actions={
                hasWritePermission && (
                    <Stack spacing={1} direction="row">
                        <RemoveModal
                            title={t('removeShipment')}
                            text={t('removeShipmentWarning')}
                            button={<Button>{t('removeShipment')}</Button>}
                            handleDelete={onDelete}
                        />
                        {shipment?.status === ShipmentStatus.COMPLETED ? (
                            <Tooltip title={emailConfigured ? '' : (t('emailNotConfigured') as string)}>
                                <span>
                                    <Button
                                        variant="outlined"
                                        onClick={onNotify}
                                        disabled={notifying || !emailConfigured}
                                    >
                                        {t('notifyShipment')}
                                    </Button>
                                </span>
                            </Tooltip>
                        ) : (
                            <Button variant="outlined" onClick={onComplete} disabled={isPendingSave}>
                                {t('completeShipment')}
                            </Button>
                        )}
                        <Button variant="contained" onClick={onEdit}>
                            {t('editShipment')}
                        </Button>
                    </Stack>
                )
            }
        >
            {shipment && (
                <Grid container spacing={3} alignItems="flex-start">
                    <Grid item xs={6}>
                        <Widget title={t('shipment')} icon={<ListAltTwoTone color="primary" />}>
                            <Grid container spacing={1}>
                                <Grid item xs={12} md={4}>
                                    <Definition label={t('date')}>{formatDateString(shipment.shipmentDate)}</Definition>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <Definition label={t('organisation')}>{shipment.receiver?.name}</Definition>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <Definition label={t('status')}>{t(shipment.status)}</Definition>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <Definition label={t('mailStatus')}>{t(shipment.mailStatus)}</Definition>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <Definition label={t('lastMailDate')}>
                                        {formatDateTimeString(shipment.lastMailDate)}
                                    </Definition>
                                </Grid>
                            </Grid>
                        </Widget>
                    </Grid>
                    <Grid item xs={6}>
                        <Widget title={t('attachments')} icon={<AttachFileTwoTone color="primary" />}>
                            <Grid container spacing={2} alignItems="stretch">
                                <Grid item xs={12} sm={12} md={12} lg={6}>
                                    <Asset
                                        asset={{
                                            id: 'shipmentDocument',
                                            fileName: t('shipmentDocument'),
                                            mimeType: 'application/pdf',
                                            downloadUrl: `/shipments/${id}/document`,
                                        }}
                                    />
                                </Grid>
                                {shipment.assets.map((asset) => (
                                    <Grid item xs={12} sm={12} md={12} lg={6} key={asset.id}>
                                        <Asset asset={asset} />
                                    </Grid>
                                ))}
                            </Grid>
                        </Widget>
                    </Grid>

                    <Grid item xs={12}>
                        <Widget title={t('progressesCategories')} icon={<ListAltTwoTone color="primary" />}>
                            <OrganisationProgressCategories height={200} progresses={shipment.progresses} showLabels />
                        </Widget>
                    </Grid>

                    <ProductProgress progresses={shipment.progresses} />

                    <Grid item xs={12}>
                        <ShipmentDetailStockMovements shipment={shipment} />
                    </Grid>
                </Grid>
            )}
        </Page>
    );
};

import { IStockMovement, IStockMovementForm } from '../models';

export const stockMovementToFormMapper = (item: IStockMovement): IStockMovementForm => {
    return {
        ...item,
        stockLocationId: item.stockLocation?.id,
        supplierId: item.supplier?.id,
        productCode: item.product.code,
        organisationId: item.organisation?.id,
        productName: item?.product?.name,
        useBatch: !!item.batch,
        batch: item.batch
            ? {
                  ...item.batch,
                  supplierName: item.batch.supplier.name,
              }
            : null,
    };
};

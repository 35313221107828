import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ControlledCheckbox, ControlledInput, Section, FormGrid } from '../../../shared';

interface Props {
    id?: string;
}

export const ProductData: FC<Props> = ({ id }) => {
    const { t } = useTranslation();

    return (
        <Section title={t('data')}>
            <FormGrid spacing={0} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <ControlledInput name="name" label={t('name')} required />
                <ControlledInput name="brandName" label={t('brandName')} />
                <ControlledInput name="regulatedName" label={t('regulatedName')} />
                {id && <ControlledInput name="code" label={t('code')} disabled />}
            </FormGrid>
            <ControlledCheckbox name="requiresReservation" label={t('requiresReservation')} sx={{ ml: 2 }} />
        </Section>
    );
};

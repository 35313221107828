import { ListAltTwoTone } from '@mui/icons-material';
import { Grid } from '@mui/material';
import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { MeasurementUnit, Widget } from '../../../shared';
import { IProgress } from '../../models';
import { useGetProgressColor } from '../../utils';
import { Bar, BarChart, Cell, LabelList, ResponsiveContainer, XAxis, YAxis } from 'recharts';

interface ProgressBar extends IProgress {
    name: string;
    percentage?: number;
    percentageNotScanned?: number;
    label?: string;
}

interface Props {
    progresses: IProgress[];
}
export const ProductProgress: FC<Props> = ({ progresses }) => {
    const { t } = useTranslation();
    const productProgresses = useMemo(() => progresses.filter(({ productId }) => !!productId), [progresses]);
    const getFill = useGetProgressColor();

    const data = useMemo(() => {
        if (productProgresses?.length) {
            const data: ProgressBar[] = productProgresses.map((progress) => {
                const percentage = Math.min((progress.totalScanned / progress.totalPlanned) * 100, 100);
                const isGram = progress.product?.measurementUnit === MeasurementUnit.GRAM;

                return {
                    ...progress,
                    name: progress.product?.name || '',
                    percentage,
                    percentageNotScanned: 100 - percentage,
                    label: isGram
                        ? `${(progress.totalScanned / 1000).toFixed(1)}/${(progress.totalPlanned / 1000).toFixed(1)} kg`
                        : `${progress.totalScanned}/${progress.totalPlanned} st`,
                };
            });
            return data.sort((a, b) => a.name.localeCompare(b.name));
        }
    }, [productProgresses]);

    return productProgresses.length ? (
        <Grid item xs={12}>
            <Widget title={t('progressesProducts')} icon={<ListAltTwoTone color="primary" />}>
                <ResponsiveContainer width="100%" height={150}>
                    {data ? (
                        <BarChart margin={{ top: 20 }} data={data}>
                            <XAxis dataKey="name" />
                            <YAxis label={{ value: '%', position: 'insideLeft' }} domain={[0, 100]} />
                            <Bar dataKey="percentage" isAnimationActive={false} stackId="a">
                                <LabelList dataKey="label" position="top" fill="black" />
                                {data.map((progress) => (
                                    <Cell fill={getFill(progress)} key={progress.category} fontSize="11px" />
                                ))}
                            </Bar>
                            <Bar dataKey="percentageNotScanned" stackId="a" fill="rgba(60, 60, 60, 0.1)" />
                        </BarChart>
                    ) : (
                        <></>
                    )}
                </ResponsiveContainer>
            </Widget>
        </Grid>
    ) : (
        <></>
    );
};

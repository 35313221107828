export const adminTranslationsNL = {
    admin: 'Admin',
    parties: 'Partijen',

    devices: 'Scanners',
    newDevice: 'Nieuwe scanner',
    deviceDeleteWarningTitle: 'Scanner verwijderen?',
    deviceDeleteWarningText: 'Ben je zeker dat je deze scanner wilt verwijderen?',

    enrollmentTitle: 'Registreer scanner',
    enrollmentStartSummary:
        'Je staat op het punt een scanner te registreren. In de volgende stap krijg je een publieke sleutel. Je kan deze sleutel gebruiken om de registratie op de scanner te vervolledigen.',
    enrollmentCompleteSummary:
        'Gebruike onderstaande code en publieke sleutel om de registratie op de scanner te vervolledigen.',
    startEnrollment: 'Start registratie',
    enrollmentDeviceCode: 'Scanner code',
    enrollmentPublicKey: 'Publieke sleutel',
    enrollmentAlreadyCompleted:
        'Deze scanner is reeds geregistreerd. Als je verder gaat zal je de scanner opnieuw moeten registreren.',

    organisations: 'Organisaties',
    organisation: 'Organisatie',
    newOrganisation: 'Nieuwe organisatie',
    organisationDeleteWarningTitle: 'Organisatie verwijderen?',
    organisationDeleteWarningText: 'Ben je zeker dat je deze organisatie wilt verwijderen?',
    features: 'Features',
    usesCaritySupport: 'Gebruikt carity support',
    usesCarityLogistics: 'Gebruikt carity logistics',
    contactInformation: 'Contact informatie',
    contact: 'Contact',
    contactsAdd: '+ Contact toevoegen',
    EMAIL: 'Email',
    PHONE: 'Telefoon',

    organisationTypes: 'Organisatie types',
    newOrganisationType: 'Nieuwe type',
    organisationTypeDeleteWarningTitle: 'Organisatie type verwijderen?',
    organisationTypeDeleteWarningText: 'Ben je zeker dat je dit type wilt verwijderen?',

    operators: 'Operatoren',
    operator: 'Operator',
    newOperator: 'Nieuwe operator',
    operatorDeleteWarningTitle: 'Operator verwijderen?',
    operatorDeleteWarningText: 'Ben je zeker dat je deze operator wilt verwijderen?',

    suppliers: 'Leveranciers',
    supplier: 'Leverancier',
    newSupplier: 'Nieuwe leverancier',
    supplierDeleteWarningTitle: 'Leverancier verwijderen?',
    supplierDeleteWarningText: 'Ben je zeker dat je deze leverancier wilt verwijderen?',

    products: 'Producten',
    product: 'Product',
    newProduct: 'Nieuw product',
    productDeleteWarningTitle: 'Product verwijderen?',
    productDeleteWarningText: 'Ben je zeker dat je dit product wilt verwijderen?',
    measurementUnit: 'Eenheid',
    weight: 'Gewicht per stuk (gr.)',
    content: 'Inhoud',
    brandName: 'Merknaam',
    regulatedName: 'Gereguleerde naam',
    ingredients: 'Ingrediënten',
    nutritionalInformation: 'Voedingswaarden',
    dietInformation: 'Leefstijlen',
    dietInformationEntry: 'Leefstijl',
    addDietInformation: '+ Leefstijl toevoegen',
    allergenInformation: 'Allergie informatie',
    levelOfContainment: 'Niveau',
    allergenType: 'Allergie type',
    addAllergenInformation: '+ Allergie informatie toevoegen',
    links: 'Links',
    linksEntry: 'Link',
    addLink: '+ Link toevoegen',
    noCategoriesFound: 'Geen categorieën gevonden',
    startSearchingCategories: 'Type om een categorie te zoeken...',
    GRAM: 'Gram',
    PIECE: 'Stuk',
    piece: 'stuk(s)',
    '30': 'Vrij van: maar dit is niet bevestigd door het testen van het product omdat een kruisbesmettingsdeclaratie niet verplicht is',
    '50': 'Afgeleid van: het product is afgeleid van bestanddelen in de gespecificeerde toestand',
    '60': 'Niet afgeleid van: het product is niet afgeleid van bestanddelen in de gespecificeerde toestand',
    CONTAINS: 'Bevat: Het artikel bevat de genoemde stof als ingrediënt (of de stof is aanwezig in een ingrediënt).',
    FREE_FROM: 'Vrij van: Het artikel is vrij van de genoemde stof (volgens receptuur).',
    MAY_CONTAIN: 'Kan bevatten: Het artikel kan de genoemde stof bevatten (als gevolg van besmetting).',
    UNDECLARED:
        'Niet opgegeven: Niet bewust opgenomen in het product en ingeschat dat een kruisbesmettingsdeclaratie niet nodig was',

    nutriScore: 'Nutri-score',
    NUTRISCORE_A: 'Nutri-score A',
    NUTRISCORE_B: 'Nutri-score B',
    NUTRISCORE_C: 'Nutri-score C',
    NUTRISCORE_D: 'Nutri-score D',
    NUTRISCORE_E: 'Nutri-score E',

    draftProducts: 'Onbekend',
    draft: 'Onbekend product',
    requiresReservation: 'Reservering via fead levering verplicht',
    categoryRequiredWhenNoDraft: 'Je moet minstens 1 category selecteren.',

    productCategories: 'Categorieën',
    productCategory: 'Categorie',
    newProductCategory: 'Nieuwe categorie',
    productCategoryDeleteWarningTitle: 'Categorie verwijderen?',
    productCategoryDeleteWarningText: 'Ben je zeker dat je deze categorie wilt verwijderen?',
    abbreviation: 'Afkorting',
    abbreviationMaxLengthError: 'Afkorting mag maximum 4 karakters bevatten',
    abbreviationUniqueError: 'Afkorting niet uniek',
    averagePricePerKg: 'Gemiddelde prijs per kg',
    priority: 'Prioriteit',
    additionalData: 'Aanvullende informatie',
    categoryInUseTitle: 'Categorie in gebruik',
    categoryInUseDescription: 'Deze categorie wordt gebruikt door een product en kan dus niet verwijdert worden.',

    stockLocations: 'Stock locaties',
    stockLocation: 'Stock locatie',
    newStockLocation: 'Nieuwe stock locatie',
    stockLocationDeleteWarningTitle: 'Stock locatie verwijderen?',
    stockLocationDeleteWarningText: 'Ben je zeker dat je deze stock locatie wilt verwijderen?',

    qrCodes: 'Qr codes',
    newQrCode: 'Nieuwe qr code',
    qrCodeDeleteWarningTitle: 'Qr code verwijderen?',
    qrCodeDeleteWarningText: 'Ben je zeker dat je deze qr code wilt verwijderen?',

    BATCH: 'Batch',

    settings: 'Instellingen',
    shipmentMailBody: 'Inhoud mail verzenddocument',
    shipmentMailSubject: 'Onderwerp mail verzenddocument',
    productHasStockMovements: 'Dit product kan niet worden verwijderd omdat het in stock bewegingen wordt gebruikt.',

    blacklistedProducts: 'Geblokkeerde producten',
    partialProductName: 'Gedeeltelijke productnaam',
    isPermanent: 'Permanent',
    blockForAllOrganisations: 'Blokeer voor alle organisaties',
    blockedForAllOrganisations: 'Geblokeerd voor alle organisaties',
    blockByProductCode: 'Blokeer via product code',
    allOrganisations: 'Alle organisaties',
    newBlacklistedProduct: 'Voeg geblokkeerd product toe',
    editBlacklistedProduct: 'Wijzig geblokkeerd product',
    requiredCategoryIdPartialProductName: 'Categorie en/of gedeeltelijke product naam dient ingevuld te zijn.',
    requiredProductCode: 'Product code dient ingevuld te zijn.',
    blacklistedProductDeleteWarningTitle: 'Geblokkering verwijderen?',
    blacklistedProductDeleteWarningText: 'Ben je zeker dat je deze blokkering wilt verwijderen?',
};

import { Alert, Snackbar } from '@mui/material';
import { GridCellParams, GridColDef, GridSortDirection } from '@mui/x-data-grid';
import { AxiosError } from 'axios';
import React, { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
    formatDateTimeString,
    Page,
    PermissionKeys,
    RowActions,
    Search,
    SortOrder,
    TableComponent,
    useHasPermission,
    useQueryParams,
} from '../../../shared';
import { useDeleteProduct, useProductsList } from '../../hooks';

export const DraftProductsPage: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { hasPermission } = useHasPermission();
    const hasWritePermission = hasPermission(PermissionKeys.PRODUCTS_WRITE);
    const [showDeleteWarning, setShowDeleteWarning] = useState(false);

    const { page, setPage, pageSize, setPageSize, sort, setSort, search, setSearch, debouncedSearch } = useQueryParams({
        defaultSort: [{ field: 'createdDate', sort: 'desc' as GridSortDirection }],
    });

    const { isPending, data } = useProductsList({
        page: page,
        pageSize: pageSize,
        sortBy: sort[0].field,
        sortOrder: sort[0].sort?.toUpperCase() as SortOrder,
        search: debouncedSearch,
        isDraft: true,
    });

    const { mutateAsync: deleteProduct } = useDeleteProduct();

    const onDelete = useCallback(
        async (id: string) => {
            try {
                await deleteProduct(id);
            } catch (err: unknown) {
                if ((err as AxiosError)?.response?.status === 400) {
                    setShowDeleteWarning(true);
                }
            }
        },
        [deleteProduct],
    );

    const columns: GridColDef[] = [
        {
            field: 'createdDate',
            headerName: t('createdDate'),
            width: 160,
            valueFormatter: ({ value }) => formatDateTimeString(value as string),
        },
        {
            field: 'createdBy',
            headerName: t('createdBy'),
            minWidth: 150,
            flex: 1,
        },
        { field: 'code', headerName: t('code'), minWidth: 150, flex: 1, sortable: false },
        { field: 'name', headerName: t('name'), minWidth: 150, flex: 1 },
        {
            field: ' ',
            headerName: '',
            width: 150,
            align: 'right',
            sortable: false,
            renderCell: ({ row: { id } }: GridCellParams) => (
                <>
                    {hasWritePermission && (
                        <RowActions
                            onEdit={() => navigate(`/admin/draft-products/${id}/edit`)}
                            onDelete={() => onDelete(id)}
                            deleteWarningTitle={t('productDeleteWarningTitle')}
                            deleteWarningText={t('productDeleteWarningText')}
                        />
                    )}
                </>
            ),
        },
    ];

    return (
        <Page title={t('draftProducts')} actions={[<Search search={search} onSearch={setSearch} />]}>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={showDeleteWarning}
                onClose={() => setShowDeleteWarning(false)}
            >
                <Alert onClose={() => setShowDeleteWarning(false)} severity="error" sx={{ width: '100%' }}>
                    {t('productHasStockMovements')}
                </Alert>
            </Snackbar>
            <TableComponent
                rows={data?.data || []}
                columns={columns}
                rowCount={data?.pagination.size || 0}
                paginationModel={{ page: page ? page - 1 : 0, pageSize }}
                onPaginationModelChange={(value) => {
                    setPage(value?.page + 1);
                    setPageSize(value?.pageSize || 5);
                }}
                loading={isPending}
                sortModel={sort}
                onSortModelChange={setSort}
                defaultSortModel={sort}
                onRowClick={(row) => hasWritePermission && navigate(`/admin/draft-products/${row.id}/edit`)}
            />
        </Page>
    );
};

import { MenuItem } from '@mui/material';
import { FC, useCallback, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { IStockLocation } from '../../../admin/models';
import {
    checkDifferentFilterAndFormValues,
    ControlledDatePicker,
    ControlledInput,
    ControlledSelect,
    FormGrid,
    ISupplier,
    Options,
    IOrganisation,
    OptionsToggle,
    useOrganisationsList,
} from '../../../shared';
import { StockMovementType } from '../../enums';
import { IStockMovementQuery } from '../../models';
import { useOperatorsList, useStockLocationsList, useSuppliersList } from '../../../admin/hooks';

interface Props {
    filter: Record<string, any>;
    onChange: (filter: IStockMovementQuery & { options: Options }) => void;
    showSupplier?: boolean;
}

export const StockMovementsFilter: FC<Props> = ({ filter, onChange, showSupplier = true }) => {
    const { t } = useTranslation();
    const form = useForm<IStockMovementQuery>({ mode: 'onChange' });

    const defaults: Options = useMemo(() => {
        return {
            filter: { type: 'title', label: t('filter') },
            date: { label: t('date'), active: false },
            type: { label: t('type'), active: false },
            operator: { label: t('operator'), active: false },
            stockLocation: { label: t('stockLocation'), active: false },
            organisation: { label: t('organisation'), active: false },
            productCode: { label: t('productCode'), active: false },
        };
    }, [t]);

    const defaultOptions: Options = useMemo(() => {
        return showSupplier
            ? {
                  ...defaults,
                  supplier: { label: t('supplier'), active: false },
              }
            : { ...defaults };
    }, [defaults, showSupplier, t]);

    const filterOptions: Options = useMemo(() => {
        return filter.options || defaultOptions;
    }, [defaultOptions, filter.options]);

    const setOptions = useCallback((options: Options) => onChange({ ...filter, options }), [filter, onChange]);

    const { data: suppliers } = useSuppliersList({ pageSize: 100 });
    const { data: operators } = useOperatorsList({ pageSize: 100 });
    const { data: stockLocations } = useStockLocationsList({ pageSize: 100 });
    const { data: organisations } = useOrganisationsList({ pageSize: 100 });

    const formValues = form.watch();

    useEffect(() => {
        form.reset(filter);
    }, [filter, form]);

    useEffect(() => {
        const { date, type, operator, supplier, stockLocation, organisation, productCode } = filterOptions;
        if (!date.active) {
            form.setValue('date', '');
        }
        if (!type.active) {
            form.setValue('type', undefined);
        }
        if (!operator.active) {
            form.setValue('operator', undefined);
        }
        if (showSupplier && !supplier?.active) {
            form.setValue('supplier', undefined);
        }
        if (!stockLocation.active) {
            form.setValue('stockLocation', undefined);
        }
        if (!organisation.active) {
            form.setValue('organisation', undefined);
        }
        if (!productCode.active) {
            form.setValue('productCode', undefined);
        }
    }, [filterOptions, form, showSupplier]);

    useEffect(() => {
        if (!!Object.keys(formValues).length && checkDifferentFilterAndFormValues(formValues, filter)) {
            onChange({ ...formValues, options: filterOptions });
        }
    }, [formValues, filter, onChange, filterOptions]);

    return (
        <FormProvider {...form}>
            <FormGrid alignItems="center" spacing={1} flexWrap="nowrap">
                <OptionsToggle options={filterOptions} onChange={setOptions} />

                {filterOptions.date.active && <ControlledDatePicker name="date" label={t('date')} size="small" />}

                {filterOptions.type.active && (
                    <ControlledSelect name="type" label={t('type')} size="small" minWidth={100}>
                        <MenuItem value="">{t('all')}</MenuItem>
                        {Object.values(StockMovementType).map((type) => (
                            <MenuItem value={type} key={type}>
                                {t(type)}
                            </MenuItem>
                        ))}
                    </ControlledSelect>
                )}

                {filterOptions.operator.active && (
                    <ControlledSelect name="operator" label={t('operator')} size="small" minWidth={100}>
                        <MenuItem value="">{t('all')}</MenuItem>
                        {(operators?.data || []).map((operator: ISupplier) => (
                            <MenuItem value={operator.id} key={operator.id}>
                                {operator.name}
                            </MenuItem>
                        ))}
                    </ControlledSelect>
                )}

                {showSupplier && filterOptions.supplier?.active && (
                    <ControlledSelect name="supplier" label={t('supplier')} size="small" minWidth={100}>
                        <MenuItem value="">{t('all')}</MenuItem>
                        {(suppliers?.data || []).map((supplier: ISupplier) => (
                            <MenuItem value={supplier.id} key={supplier.id}>
                                {supplier.name}
                            </MenuItem>
                        ))}
                    </ControlledSelect>
                )}

                {filterOptions.stockLocation.active && (
                    <ControlledSelect name="stockLocation" label={t('stockLocation')} size="small" minWidth={100}>
                        <MenuItem value="">{t('all')}</MenuItem>
                        {(stockLocations?.data || []).map((stockLocation: IStockLocation) => (
                            <MenuItem value={stockLocation.id} key={stockLocation.id}>
                                {stockLocation.name}
                            </MenuItem>
                        ))}
                    </ControlledSelect>
                )}

                {filterOptions.organisation.active && (
                    <ControlledSelect name="organisation" label={t('organisation')} size="small" minWidth={100}>
                        <MenuItem value="">{t('all')}</MenuItem>
                        {(organisations?.data || []).map((organisation: IOrganisation) => (
                            <MenuItem value={organisation.id} key={organisation.id}>
                                {organisation.name}
                            </MenuItem>
                        ))}
                    </ControlledSelect>
                )}

                {filterOptions.productCode.active && (
                    <ControlledInput name="productCode" label={t('productCode')} size="small" />
                )}
            </FormGrid>
        </FormProvider>
    );
};

import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { Check, ChevronLeft, ChevronRight, Close } from '@mui/icons-material';
import { Button, Checkbox, FormControlLabel, Stack } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { boolean, object } from 'yup';
import { ContactType, ControlledCheckbox, IOrganisation, Loading, Widget } from '../../../shared';
import { useCompleteShipments, useShipmentsList } from '../../hooks';

interface Props {
    selectedShipmentIds: string[];
}

type Params = { date: string };
type FormData = { sendMails: boolean };

export const ShipmentCompleteSendMailsPage: FC<Props> = ({ selectedShipmentIds }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { date } = useParams<keyof Params>() as Params;
    const [downloadDocuments, setDownloadDocuments] = useState(window.carity.environment.downloadAllShipmentDocuments);

    const { mutateAsync: completeShipments } = useCompleteShipments();
    const { data: shipments, isPending } = useShipmentsList({
        page: 1,
        pageSize: 10000,
        shipmentDate: date,
    });
    const selectedShipments = useMemo(
        () => shipments?.data?.filter((shipment) => selectedShipmentIds.includes(shipment.id)),
        [shipments, selectedShipmentIds],
    );

    const organisationHasMailContact = useCallback((organisation: IOrganisation) => {
        return (organisation?.contacts?.filter((contact) => contact.type === ContactType.Email)?.length || 0) > 0;
    }, []);

    const schema = object().shape({
        sendMails: boolean().required(),
    });

    const form = useForm<FormData>({
        resolver: yupResolver(schema),
        defaultValues: {
            sendMails: true,
        },
        mode: 'all',
    });

    const columns: GridColDef[] = [
        {
            field: 'hasMailingContact',
            headerName: t('hasMailingContact'),
            minWidth: 150,
            align: 'center',
            renderCell: (value) => (
                <>
                    {organisationHasMailContact(value.row.receiver) ? (
                        <Check color="success" />
                    ) : (
                        <Close color="warning" />
                    )}
                </>
            ),
        },
        {
            field: 'receiver',
            headerName: t('receiver'),
            valueFormatter: ({ value }) => value?.name,
            minWidth: 150,
            flex: 2,
            sortable: false,
        },
    ];

    const onSubmit = async (item: FormData) => {
        await completeShipments({ shipments: selectedShipmentIds, sendMails: item.sendMails });
        if (downloadDocuments) {
            if (selectedShipmentIds.length === 1) {
                window.open(`/logistics-api/shipments/${selectedShipmentIds[0]}/document`, '__blank');
            } else {
                window.open(
                    `/logistics-api/shipments/documents?shipmentIds=${selectedShipmentIds.join('&shipmentIds=')}`,
                    '__blank',
                );
            }
        }
        navigate(`/planning/shipments`);
    };

    return isPending ? (
        <Loading />
    ) : (
        <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)}>
                <Widget
                    footer={
                        <Stack direction="row" justifyContent="space-between">
                            <Button
                                startIcon={<ChevronLeft />}
                                onClick={() =>
                                    navigate(`/planning/shipments/shipments-complete-wizard/${date}/shipments`)
                                }
                            >
                                {t('previous')}
                            </Button>
                            <Button endIcon={<ChevronRight />} variant="contained" color="primary" type="submit">
                                {t('next')}
                            </Button>
                        </Stack>
                    }
                >
                    <Stack>
                        <ControlledCheckbox name="sendMails" label={t('sendMailsOption')} />
                        <FormControlLabel
                            label={t('downloadDocuments') as string}
                            sx={{ mb: 2 }}
                            control={
                                <Checkbox
                                    onChange={() => setDownloadDocuments(!downloadDocuments)}
                                    checked={downloadDocuments}
                                />
                            }
                        />
                    </Stack>
                    <DataGrid
                        autoHeight
                        rows={selectedShipments || []}
                        columns={columns}
                        hideFooter={true}
                        disableColumnFilter
                        disableColumnMenu
                    />
                </Widget>
            </form>
        </FormProvider>
    );
};

import { Grid } from '@mui/material';
import { GridSpacing } from '@mui/material/Grid/Grid';
import { ResponsiveStyleValue } from '@mui/system';
import { Children, ComponentProps, FC } from 'react';

interface Props extends ComponentProps<typeof Grid> {
    vertical?: boolean;
    spacing?: ResponsiveStyleValue<GridSpacing>;
}

export const FormGrid: FC<Props> = ({ vertical, spacing = 2, children, ...gridProps }) => {
    return (
        <Grid container direction={vertical ? 'column' : 'row'} spacing={spacing} {...gridProps}>
            {Children.map(children, (child, key) =>
                child ? (
                    <Grid item key={key}>
                        {child}
                    </Grid>
                ) : (
                    <></>
                ),
            )}
        </Grid>
    );
};

import { Delete } from '@mui/icons-material';
import { Button, IconButton, MenuItem, Stack } from '@mui/material';
import { FC } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ContactType, ControlledInput, ControlledSelect, Section } from '../../../shared';

export const OrganisationContacts: FC = () => {
    const { t } = useTranslation();
    const form = useFormContext();
    const {
        fields: contacts,
        append,
        remove,
    } = useFieldArray({
        control: form.control,
        name: 'contacts',
    });

    return (
        <Section title={t('contactInformation')}>
            {contacts.map((contact, idx) => (
                <Stack alignItems="flex-start" pl={2} spacing={2} direction="row" key={contact.id}>
                    <ControlledSelect name={`contacts.${idx}.type`} label={t('type')} required>
                        {Object.values(ContactType).map((type) => (
                            <MenuItem key={type} value={type}>
                                {t(type)}
                            </MenuItem>
                        ))}
                    </ControlledSelect>
                    <ControlledInput name={`contacts.${idx}.data`} label={t('contact')} required />
                    <IconButton onClick={() => remove(idx)}>
                        <Delete />
                    </IconButton>
                </Stack>
            ))}
            <Button onClick={() => append({ type: ContactType.Email, data: '' })} color="secondary" sx={{ pl: 2 }}>
                {t('contactsAdd')}
            </Button>
        </Section>
    );
};

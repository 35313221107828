import { FactCheck, Warning, WarningAmber } from '@mui/icons-material';
import { Stack, Tooltip, Typography } from '@mui/material';
import React, { FC, SVGProps, useMemo } from 'react';
import { Bar, BarChart, Cell, LabelList, LabelProps, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import { IProductCategory } from '../../../admin/models';
import { formatDateString, IOrganisation } from '../../../shared';
import { ShipmentStatus } from '../../enums';
import { useAllCategories } from '../../hooks';
import { IProgress } from '../../models';
import { compareProductCategory, useGetProgressColor } from '../../utils';
import { useTranslation } from 'react-i18next';

interface Props {
    organisation?: IOrganisation;
    shipmentDate?: string;
    shipmentStatus?: ShipmentStatus;
    shipmentAmountOfDrafts?: number;
    height: number;
    progresses: IProgress[];
    showLabels?: boolean;
    visibleCategories?: IProductCategory[];
}

const renderCustomizedLabel = ({ x, width, height, value }: Omit<SVGProps<SVGTextElement>, 'viewBox'> & LabelProps) => {
    return (
        value && (
            <g>
                <foreignObject
                    x={(x as number) - 10 + (width as number) / 2}
                    y={height}
                    width={20}
                    height={20}
                    textAnchor="middle"
                >
                    <Warning sx={{ color: 'red' }} fontSize={'small'} />
                </foreignObject>
            </g>
        )
    );
};

interface ProgressBar extends IProgress {
    name: string;
    abbreviation: string;
    priority?: number | null;
    percentage?: number;
    percentageNotScanned?: number;
    label?: string;
    limit?: string;
    showWarning?: boolean;
}

export const OrganisationProgressCategories: FC<Props> = ({
    organisation,
    height,
    progresses,
    showLabels = false,
    shipmentDate,
    shipmentStatus,
    shipmentAmountOfDrafts,
    visibleCategories,
}) => {
    const categories = useAllCategories();
    const { t } = useTranslation();
    const categoryProgresses = useMemo(() => progresses.filter(({ category }) => !!category), [progresses]);
    const getFill = useGetProgressColor();

    const data = useMemo(() => {
        if (categoryProgresses && categories?.length) {
            const data: ProgressBar[] = categoryProgresses
                .filter(({ totalScanned, totalPlanned }) => !(totalScanned === 0 && totalPlanned === 0))
                .map((progress) => {
                    const percentage = Math.min((progress.totalScanned / progress.totalPlanned) * 100, 100);
                    const category = categories.find(({ id }) => id === progress.category);
                    return {
                        ...progress,
                        name: category?.name || `${t('unknown')}`,
                        abbreviation: category?.abbreviation || '',
                        priority: category?.priority,
                        percentage,
                        percentageNotScanned: 100 - percentage,
                        label: `${(progress.totalScanned / 1000).toFixed(1)}/${(progress.totalPlanned / 1000).toFixed(
                            1,
                        )} kg`,
                        limit:
                            progress.totalScanned - progress.totalPlanned > -10000
                                ? `${progress.totalScanned - progress.totalPlanned > 0 ? '+' : ''}${(
                                      (progress.totalScanned - progress.totalPlanned) /
                                      1000
                                  ).toFixed()}`
                                : '',
                        showWarning: progress.totalScanned === 0,
                    };
                });
            if (visibleCategories) {
                visibleCategories.forEach((category) => {
                    if (!data.some((progress) => progress.category === category.id)) {
                        data.push({
                            category: category.id,
                            totalScanned: 0,
                            totalPlanned: 0,
                            name: category.name,
                            abbreviation: category.abbreviation,
                            priority: category.priority,
                        });
                    }
                });
            }
            return data.sort(compareProductCategory);
        } else return undefined;
    }, [categoryProgresses, categories, visibleCategories, t]);

    return (
        <>
            {organisation && (
                <Stack direction="row" mb={1.5}>
                    <Stack direction="row" sx={{ flexGrow: 1 }} alignItems="center" justifyContent="center">
                        {shipmentStatus === ShipmentStatus.COMPLETED && (
                            <FactCheck fontSize="small" sx={{ color: 'green', mr: 1 }} />
                        )}
                        <Typography variant="subtitle2">
                            {organisation?.name} - {formatDateString(shipmentDate)}
                        </Typography>
                    </Stack>

                    {!!shipmentAmountOfDrafts && (
                        <Tooltip title={t('amountOfDrafts', { count: shipmentAmountOfDrafts })} arrow>
                            <Stack direction="row" sx={{ color: 'orange' }}>
                                <WarningAmber fontSize="small" sx={{ mr: 0.5 }} />
                                <Typography variant="body1">{shipmentAmountOfDrafts}</Typography>
                            </Stack>
                        </Tooltip>
                    )}
                </Stack>
            )}

            <ResponsiveContainer width="100%" height={height - 50}>
                {data ? (
                    <BarChart margin={{ top: 20 }} data={data}>
                        {showLabels && <XAxis dataKey="name" />}
                        {!showLabels && <XAxis dataKey="abbreviation" stroke="black" fontSize="9px" interval={0} />}
                        <YAxis label={{ value: '%', position: 'insideLeft' }} domain={[0, 100]} hide={!showLabels} />
                        <Bar dataKey="percentage" isAnimationActive={false} stackId="a">
                            {showLabels && <LabelList dataKey="label" position="top" fill="black" />}
                            {!showLabels && <LabelList dataKey="showWarning" content={renderCustomizedLabel} />}
                            {!showLabels && <LabelList dataKey="limit" position="top" fill="black" strokeWidth={0.1} />}
                            {data.map((progress) => (
                                <Cell fill={getFill(progress)} key={progress.category} fontSize="11px" />
                            ))}
                        </Bar>
                        <Bar dataKey="percentageNotScanned" stackId="a" fill="rgba(60, 60, 60, 0.1)" />
                    </BarChart>
                ) : (
                    <></>
                )}
            </ResponsiveContainer>
        </>
    );
};
